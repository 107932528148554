import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { Toggle } from '@bolt/ui-shared/configuration';
import { Configuration } from '@bolt/ui-shared/configuration';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { HotConfigurationTypeEnum } from 'app/modules/clone/models/hot-configuration-type.enum';
import { HotConfiguration } from 'app/modules/clone/models/hot-configuration.model';


@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Updates a configuration with the given data.
   *
   * @param configuration Configuration
   * @param value any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  updateConfiguration(
    configuration: Configuration,
    value: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl('appConfigurationManager.configuration.endpoint');

    const body: any = {
      name: configuration.name,
      description: configuration.description,
      type: configuration.type,
      value: value
    };

    const sub: Subscription = this.doPutRequest(
      { url: url + `/${configuration.id}`, body: body },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const mappedRes: Configuration = new Configuration(successResponse.item);
          onSuccessDo(mappedRes);
        } catch (error) {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return sub;
  }

  /**
   * Updates a toggle with the given data.
   *
   * @param toggle Toggle
   * @param value any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  updateToggle(
    toggle: Toggle,
    value: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    let url: string;

    if (toggle.getIsUiDbToggle()) {
      url = this.generateUrl('appConfigurationManager.toggle.endpoint') + `/${toggle.getId()}`;
    } else {
      url = this.generateUrl('appConfigurationManager.otherToggles.updateHotConfiguration.endpoint', {
          '{hotConfigurationType}': toggle.getName()
        }
      );
    }

    const body: any = {
      name: toggle.getName(),
      description: toggle.getDescription(),
      value: value
    };

    const sub: Subscription = this.doPutRequest(
      { url, body: body },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const mappedRes: Toggle = new Toggle({ ...successResponse.item, isUiDbToggle: toggle.getIsUiDbToggle()});
          onSuccessDo(mappedRes);
        } catch (error) {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return sub;
  }

  /**
   * Loads additional toggles
   *
   * @param hotConfigurationType HotConfigurationTypeEnum
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  loadOtherToggles(
    hotConfigurationType: HotConfigurationTypeEnum,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction,
  ): Subscription  {
    const params: any = {
      '{hotConfigurationType}': hotConfigurationType.toString()
    };

    const request: any = {
      url: this.generateUrl(
        'appConfigurationManager.otherToggles.fetchHotConfiguration.endpoint',
        params
      )
    };

    const subs: Subscription = this.doGetRequest(
      request,
      (response: StormServiceResponseSingle) => {
        const hotConfig: HotConfiguration = new HotConfiguration(response.item);
        onSuccessDo(hotConfig);
      },
      (errorResponse: any) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;

  }
}
