import { Component } from '@angular/core';
import { AppConfigurationManager, AppConfigProvider, Toggle } from '@bolt/ui-shared/configuration';
import { isNumber as _isNumber } from 'lodash';

import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { AppConfigurationService } from '../../service/app-configuration/app-configuration.service';
import { HotConfigurationTypeEnum } from 'app/modules/clone/models/hot-configuration-type.enum';
import { HotConfiguration } from 'app/modules/clone/models/hot-configuration.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-app-toggle-list',
  template: require('./bolt-app-toggle-list.html'),
  styles: [require('./bolt-app-toggle-list.scss')]
})
export class BoltAppToggleListComponent extends StormComponent {
  protected pager: MemoryPager;
  protected selectedToggle: Toggle;
  protected showEditSidePanel: boolean;
  protected otherToggles: Toggle[] = [];

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected appConfigurationService: AppConfigurationService,
    protected appConfig: AppConfigProvider
  ) {
    super();
    this.setupPager();
    this.loadToggles();
    this.showEditSidePanel = false;
  }

  /**
   * Closes the edit side panel.
   *
   * @returns void
   */
  protected closeEditSidePanel(): void {
    this.showEditSidePanel = false;
    this.selectedToggle = undefined;
  }

  /**
   * Open the edit side panel.
   *
   * @param selected Toggle
   * @returns void
   */
  protected openEditSidePanel(selected: Toggle) {
    this.selectedToggle = selected;
    this.showEditSidePanel = true;
  }

  /**
   * Returns the sorting criteria for toggles.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (elemA: Toggle, elemB: Toggle) => {
      const nameA: string = elemA.getName().trim().toLowerCase();
      const nameB: string = elemB.getName().trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has to disable the edit button.
   *
   * @param toggle Toggle
   * @returns boolean
   */
  protected hasDisableEditButton(toggle: Toggle) {
    return !toggle.isPersisted();
  }

  /**
   * Indicates if it has toggles.
   *
   * @returns boolean
   */
  protected hasToggles(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has additional toggles not stored in  the UI database.
   *
   * @returns boolean
   */
  protected hasOtherToggles(): boolean {
    return this.otherToggles.length > 0;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Loads the current toggles.
   *
   * @returns void
   */
  protected loadToggles(): void {
    this.pager.setRecords(this.appConfigurationManager.getTogglesAsArray());
    this.appConfigurationService.loadOtherToggles(
      HotConfigurationTypeEnum.AUTO_TRANSLATE_TASKS_CRONJOB_TOGGLE,
      (hotConfig: HotConfiguration) => {
        const toggle = new Toggle({ name: hotConfig.code, id: hotConfig.id, value: hotConfig.value === 'true', isUiDbToggle: false});
        this.otherToggles.push(toggle);
      },
      (error: ErrorHelper) => { }
    );
  }

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }

  /**
   * Updates the list with the given toggle.
   *
   * @param toggle Toggle
   * @returns void
   */
  protected updateToggle(toggle: Toggle): void {
    if (!toggle.getIsUiDbToggle()) {
      this.otherToggles = this.otherToggles.map((existingToggle) =>
        existingToggle.getId() === toggle.getId() ? new Toggle({ ...existingToggle, value: toggle.getValue()}) : existingToggle
      );
    } else {
      this.appConfigurationManager.getToggles().set(toggle.getName(), toggle);
      this.pager.setRecords(this.appConfigurationManager.getTogglesAsArray());

      this.pager.setPageNumberFor(
        toggle,
        (toggleA: Toggle, newToggle: Toggle) => toggleA.getName() === newToggle.getName()
      );
    }
  }
}
