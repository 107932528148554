export class FeedbackQuestionBase<T> {
  field: string;
  label: string;
  description: string;
  required: boolean;
  order: number;
  placeholder: string;
  type: string;
  value: T;

  constructor(data: {
      field: string,
      order: number,
      type: string
      description?: string,
      label?: string,
      placeholder?: string,
      required?: boolean,
      value?: T,
    }
  ) {
    this.field = data.field;
    this.order = data.order;
    this.type = data.type;
    this.description = data.description || '';
    this.label = data.label || '';
    this.placeholder = data.placeholder || '';
    this.required = !!data.required;
    this.value = data.value;
  }
}
