export const modulesPath = {
  menu: {
    search: {
      path: 'menu-search'
    },
    titles: {
      path: 'menu-titles'
    },
    projects: {
      path: 'menu-projects'
    },
    talent: {
      path: 'menu-talent'
    },
    character: {
      path: 'menu-character'
    },
    role: {
      path: 'menu-role'
    },
    tasks: {
      path: 'menu-tasks'
    },
    pie: {
      path: 'menu-pie'
    },
    metadataExport: {
      path: 'menu-metadata-export'
    }
  },
  admin: {
    users: {
      path: 'admin-users'
    },
    xslt: {
      path: 'admin-xslt'
    },
    privilege: {
      path: 'admin-privilege'
    },
    systemHeartbeat: {
      path: 'admin-system-heartbeat'
    },
    toggle: {
      path: 'admin-toggle'
    },
    uiConfiguration: {
      path: 'admin-ui-configuration'
    },
    masterData: {
      languages: {
        path: 'admin-masterdata-languages'
      },
      territory: {
        path: 'admin-masterdata-territory'
      },
      account: {
        path: 'admin-masterdata-account'
      },
      productionCompany: {
        path: 'admin-masterdata-management'
      },
      dubbingStudio: {
        path: 'admin-masterdata-management'
      },
    },
    cpm: {
      configurations: {
        path: 'admin-cpm-configurations'
      },
      eventsMonitor: {
        path: 'admin-cpm-events-monitor'
      },
      healthCheck: {
        path: 'admin-cpm-health-check'
      }
    }
  },
  cat: {
    path: 'cat',
    original: {
      path: 'cat-original'
    },
    localized: {
      path: 'cat-localized'
    },
    character: {
      original: {
        path: 'character-original-fields'
      },
      localized: {
        path: 'character-localized-fields'
      },
      productAssociation: {
        path: 'character-product-association'
      },
      aka: {
        path: 'character-aka'
      }
    },
    term: {
      path: 'cat-term',
      original: {
        path: 'cat-term-original-fields'
      },
      localized: {
        path: 'cat-term-localized-fields'
      },
      productAssociation: {
        path: 'cat-term-product-association'
      },
    },
    subproduct: {
      path: 'cat-subproduct',
      original: {
        path: 'cat-subproduct-original-fields'
      },
      localized: {
        path: 'cat-subproduct-localized-fields'
      },
      productAssociation: {
        path: 'cat-subproduct-product-association'
      },
    }
  },
  character: {
    path: 'character',
    details: {
      path: 'character-details'
    }
  },
  pie: {
    import: {
      english: {
        path: 'pie-import-english'
      },
      path: 'pie-import'
    },
    export: {
      path: 'pie-export'
    }
  },
  projectDashboard: {
    path: 'project-dashboard'
  },
  titles: {
    path: 'titles',
    details: {
      path: 'titles-details',
      autopopulate: {
        path: 'titles-details-autopopulate',
      },
      autotranslate: {
        path: 'titles-details-autotranslate',
      },
      original: {
        path: 'titles-details-original-fields'
      },
      english: {
        root: {
          path: 'titles-details-english-root-fields'
        }
      },
      locale: {
        path: 'titles-details-locale',
        editHistory: {
          path: 'titles-details-locale-fields-history'
        }
      },
      language: {
        path: 'titles-details-language-fields'
      },
      metadata: {
        path: 'titles-details-metadata',
      },
      territory: {
        path: 'titles-details-territory-fields'
      },
      account: {
        path: 'titles-details-account-fields'
      },
      userGroups: {
        path: 'titles-details-user-groups'
      }
    },
    credits: {
      path: 'titles-credits',
      original: {
        path: 'titles-credits-original'
      },
      localized: {
        path: 'titles-credits-localized'
      },
      talent: {
        path: 'titles-credits-talent',
        localization: {
          path: 'titles-credits-talent-localization'
        }
      },
      role: {
        path: 'titles-credits-role',
        localization: {
          path: 'titles-credits-role-localization'
        }
      }
    },
    metadataExport: {
      path: 'titles-metadata-export',
      svod: {
        path: 'titles-metadata-export-svod'
      }
    },
    subtitles: {
      path: 'titles-subtitles',
      original: {
        path: 'titles-subtitles-original-fields'
      },
      localized: {
        path: 'titles-subtitles-localized-fields'
      },
    }
  }
};
