import { Component, Input, OnChanges, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { finalize } from 'rxjs/operators';
import { find as _find, isObject as _isObject } from 'lodash';

import { TitleMetadataInterface } from '../../models/title-metadata.model';
import { TitleInterface, Title, TitleType } from '../../models/title.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { BoltTitleMetadataFiltersInterface } from '../bolt-title-metadata-filters/bolt-title-metadata-filters.component';
import { JobStatusDetails } from 'app/modules/common/models/job-status/job-status-details.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { EventTypeEnum } from 'app/modules/common/models/job-status/event-type.enum';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { JobStatusService } from 'app/modules/common/services/job-status/job-status.service';


@Component({
  selector: 'bolt-title-metadata',
  template: require('./bolt-title-metadata.html'),
  styles: [require('./bolt-title-metadata.scss')]
})
export class BoltTitleMetadataComponent implements OnChanges {

  @Input('BoltTitleMetadataTitle') title: TitleInterface = new Title();
  @Output('LocalizationChanged') localizationChangeEvent: EventEmitter<any> = new EventEmitter();

  selectedMetadata: TitleMetadataInterface;
  isAutoPopulateInProgress: boolean = false;
  isAutoTranslateInProgress: boolean = false;

  protected filters: BoltTitleMetadataFiltersInterface;
  protected revealLocale: string;
  protected titleTypes = TitleType;
  protected localizationsUpdate: boolean = false;
  protected metadataCollection: CollectionManagerCollectionInterface;
  protected autoPopulatePollingAction: PullingActionHelper;
  protected autoTranslatePollingAction: PullingActionHelper;
  protected pollingAttempts: number = 20;
  protected eventFlag: boolean;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected notificationService: NotificationService,
    protected jobStatusService: JobStatusService
  ) {
    this.autoPopulatePollingAction = new PullingActionHelper();
    this.autoTranslatePollingAction = new PullingActionHelper();
  }

  setSelectedMetadata(titleMetadata: any) {
    if (
      titleMetadata instanceof FeatureMetadata ||
      titleMetadata instanceof SeriesMetadata ||
      titleMetadata instanceof SeasonMetadata ||
      titleMetadata instanceof EpisodeMetadata
    ) {
      this.selectedMetadata = titleMetadata;
    } else {
      this.selectedMetadata = undefined;
      this.forceUpdateFromLocalizationChange();
    }

    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title && changes.title.currentValue && this.selectedMetadata) {
      this.setSelectedMetadata(_find(this.title.localizations, { id: this.selectedMetadata.id }));
    }
  }

  onAutopopulate(jobStatusDetails: JobStatusDetails): void {
    this.isAutoPopulateInProgress = true;

    this.notificationService.handleNotice(
      'The auto-populate request was successfully sent to server and the process has started.'
    );

    this.autoPopulatePollingAction.set(
      () => this.checkProcessStatus(jobStatusDetails),
      this.pollingAttempts
    );

    this.autoPopulatePollingAction.execute();
  }

  onAutoTranslate(jobStatusDetails: JobStatusDetails): void {
    this.isAutoTranslateInProgress = true;

    this.notificationService.handleNotice(
      'The auto-translate request was successfully sent to server and the process has started.'
    );

    this.autoTranslatePollingAction.set(
      () => this.checkProcessStatus(jobStatusDetails),
      this.pollingAttempts
    );

    this.autoTranslatePollingAction.execute();
  }

  /**
   * Sync when job is completed to refresh the localization list.
   *
   * @param jobStatusDetails JobStatusDetails
   * @returns void
   */
  protected checkProcessStatus(jobStatusDetails: JobStatusDetails): void {
    this.jobStatusService.fetchJobEventStatus(
      jobStatusDetails.uuid
    ).pipe(
      finalize(
        () => this.finallyFetchingCurrentEventStatus(jobStatusDetails)
      )
    )
    .subscribe(
      (eventStatusDetails: JobStatusDetails) => {
        this.successfullyFetchingCurrentEventStatus(eventStatusDetails);
      },
      (error: ErrorHelper) => this.failedFetchingCurrentEventStatus(error, jobStatusDetails)
    );
  }

  /**
   * Checks if cloning status checks flow needs to continue or not.
   *
   * @returns void
   */
  protected finallyFetchingCurrentEventStatus(eventStatusDetails: JobStatusDetails): void {
    try {
      if (eventStatusDetails.eventType === EventTypeEnum.AUTO_POPULATE) {
        if (!this.autoPopulatePollingAction.wasReset()) {
          this.autoPopulatePollingAction.execute();
        }
      } else {
        if (!this.autoTranslatePollingAction.wasReset()) {
          this.autoTranslatePollingAction.execute();
        }
      }
    } catch (error) {
      this.failedFetchingCurrentEventStatus(error, eventStatusDetails);
    }
  }

  /**
   * Adds the error to the polling errors list
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected failedFetchingCurrentEventStatus(error: ErrorHelper, eventStatusDetails: JobStatusDetails): void {
    let message: string;

    if (eventStatusDetails.eventType === EventTypeEnum.AUTO_POPULATE && this.autoPopulatePollingAction) {
      this.autoPopulatePollingAction.reset();
      message = 'The auto-populate process has failed.';
      this.isAutoPopulateInProgress = false;
    } else if (eventStatusDetails.eventType === EventTypeEnum.AUTO_TRANSLATE && this.autoTranslatePollingAction) {
      this.autoTranslatePollingAction.reset();
      message = 'Failed auto-translate process has failed.';
      this.isAutoTranslateInProgress = false;
    }

    this.notificationService.handleError(message, error);
  }

  /**
   * Checks if the status is completed.
   *
   * @param currentStatus JobStatusDetails
   * @returns void
   */
  protected successfullyFetchingCurrentEventStatus(currentStatus: JobStatusDetails): void {
    if (currentStatus.isCompleted()) {
      let message: string;
      if (currentStatus.eventType === EventTypeEnum.AUTO_POPULATE) {
        message = 'Title auto-populate process finished successfully.';
        this.autoPopulatePollingAction.reset();
        this.isAutoPopulateInProgress = false;
      } else {
        message = 'Title auto-translate process finished successfully.';
        this.autoTranslatePollingAction.reset();
        this.isAutoTranslateInProgress = false;
      }

      this.forceUpdateFromLocalizationChange();
      this.notificationService.handleSuccess(message);
    } else if (currentStatus.isError()) {
      const errorToDisplay: ErrorHelper = new ErrorHelper(currentStatus.errorMessage);

      this.failedFetchingCurrentEventStatus(errorToDisplay, currentStatus);
    }
  }

  /**
   * Indicates if it has a selected metadata.
   *
   * @returns boolean
   */
  protected hasSelectedMetadata(): boolean {
    return _isObject(this.selectedMetadata);
  }

  /**
   * Indicates if it has to display the episode list of the current season.
   *
   * @returns boolean
   */
  protected hasDisplaySeasonEpisodeList(): boolean {
    const hasIt: boolean = this.title.isEpisode() || this.title.isSeason();
    return hasIt;
  }

  /**
   * Retrieves the current episode id.
   *
   * @returns number
   */
  protected retrieveEpisodeId(): number {
    if (this.title.isEpisode()) {
      return this.title.id;
    }
  }

  /**
   * Retrieves the season id.
   *
   * @returns number
   */
  protected retrieveSeasonId(): number {
    if (this.title.isSeason()) {
      return this.title.id;
    } else if (this.title.isEpisode()) {
      return (<EpisodeMetadata>this.title).seasonId;
    }
  }

  /**
   * Set the created localization as the selected one.
   *
   * @param localization Title
   * @returns void
   */
  protected selectNewLocalization(localization: Title): void {
    this.selectedMetadata = localization;
    this.forceUpdateFromLocalizationChange();
  }

  /**
   * Forces an update in the current localization.
   *
   * @returns void
   */
  protected forceUpdateFromLocalizationChange(): void {
    this.localizationsUpdate = true;
    this.localizationChangeEvent.emit();
  }
}
