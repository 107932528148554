import { Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy, isObject as _isObject, clone as _clone } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Episode } from 'app/modules/title/models/episode.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Season } from 'app/modules/title/models/season.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleService } from 'app/modules/title/services/title.service';
import { TitleType } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-autopopulate-metadata-list',
  template: require('./bolt-autopopulate-metadata-list.html'),
  styles: [require('./bolt-autopopulate-metadata-list.scss')],
})
export class BoltAutopopulateMetadataListComponent extends StormComponent implements OnDestroy, OnInit {
  @Input() episode: Episode;
  @Output('selected') selectEvent: EventEmitter<Episode[]>;

  autopopulateForm: FormGroup;
  errorFlag: boolean;
  episodeOptions: Episode[];
  selectedEpisodes: Episode[];
  protected readonly errorStatus: DataStatusEnum = DataStatusEnum.error;
  protected optionsSubscription: Subscription;
  protected seasonSubscription: Subscription;

  constructor(protected formBuilder: FormBuilder, protected layoutHandler: LayoutHandlerService, protected titleService: TitleService) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchEpisodeOptions();
  }

  ngOnDestroy() {
    this.cancelSubscriptions();
    this.reset();
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = !this.hasTitleOptions() && !this.hasErrorTryingToFetchEpisodes();
    return hasIt;
  }

  /**
   * Indicates if it failed trying to fetch the episodes.
   *
   * @returns boolean
   */
  hasErrorTryingToFetchEpisodes(): boolean {
    const hasIt: boolean = !this.hasTitleOptions() && this.errorFlag;
    return hasIt;
  }

  /**
   * Indicates if it has title options.
   *
   * @returns boolean
   */
  hasTitleOptions(): boolean {
    const hasIt: boolean = this.episodeOptions.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has all the options selected.
   *
   * @returns boolean
   */
  isAllSelected(): boolean {
    const isIt: boolean = this.selectedEpisodes.length === this.episodeOptions.length;
    return isIt;
  }

  /**
   * Removes the given option from the current selected options.
   *
   * @param episode Episode
   * @returns void
   */
  removeSelected(episode: Episode): void {
    const index: number = this.selectedEpisodes.findIndex((option: Episode) => option.id === episode.id);

    this.selectedEpisodes.splice(index, 1);
  }

  /**
   * Toggles all selections.
   *
   * @returns void
   */
  toggleAll(): void {
    if (this.isAllSelected()) {
      this.selectedEpisodes = new Array();
    } else {
      this.selectedEpisodes = _clone(this.episodeOptions);
    }

    this.selectEvent.emit(this.selectedEpisodes);
  }

  /**
   * Updates the value of the given option in the current selections.
   *
   * @param episode Episode
   * @returns void
   */
  updateSelected(episode: Episode): void {
    if (this.isSelected(episode)) {
      this.removeSelected(episode);
    } else {
      this.selectedEpisodes.push(episode);
    }

    this.selectEvent.emit(this.selectedEpisodes);
  }

  /**
   * Fetches the season and episodes for the current episode.
   *
   * @returns void
   */
  protected fetchEpisodeOptions(): void {
    this.layoutHandler.changeToFetching();
    this.changeStatusToFetchingData();

    this.seasonSubscription = this.titleService
      .fetchProduct({
        productId: this.episode.seasonId,
        productType: TitleType.season,
      })
      .subscribe(
        (response: StormServiceResponseSingle) => {
          this.fetchAllEpisodesOfSeason(response.item);
        },
        () => {
          this.changeStatusToError();
        }
      );
  }

  /**
   * Fetches the episodes for the given season option.
   *
   * @returns void
   */
  protected fetchAllEpisodesOfSeason(season: Season): void {
    if (season && season.numberOfEpisodes > 0) {
      const data: any = {
        titleType: TitleType.episode,
        seasonId: season.id,
        locale: '*_*_*_*',
        _size: 5000,
      };

      this.optionsSubscription = this.titleService.filterTitles(data).subscribe(
        (response: StormServiceResponseCollection) => {
          this.episodeOptions = this.applyFilter(response.collection, this.episode.id);
          this.layoutHandler.changeToReading();
          this.changeStatusToDataFound();
        },
        (error) => {
          this.layoutHandler.changeToReading();
          this.changeStatusToDataFound();
          this.errorFlag = true;
          console.error(error);
        }
      );
    } else {
      this.layoutHandler.changeToReading();
      this.changeStatusToDataFound();
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.errorFlag = false;
    this.selectEvent = new EventEmitter();
    this.episodeOptions = new Array();
    this.selectedEpisodes = new Array();
    this.setupForm();
  }

  /**
   * Indicates if the given option is the current selection.
   *
   * @param episode Episode
   * @returns boolean
   */
  protected isSelected(episode: Episode): boolean {
    const isIt = this.selectedEpisodes.some(
      (selectedEpisode: Episode) => episode.id === selectedEpisode.id
    );

    return isIt;
  }

  /**
   * Retrieves the locking status label for the current position.
   *
   * @returns string
   */
  protected retrieveLockingStatusLabel(): string {
    const label: string = `Lock Status`;
    return label;
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.episodeOptions = new Array();
  }

  /**
   * Cancels the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.optionsSubscription)) {
      this.optionsSubscription.unsubscribe();
    }

    if (_isObject(this.seasonSubscription)) {
      this.seasonSubscription.unsubscribe();
    }
  }

  /**
   * Set up the clone form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.autopopulateForm = this.formBuilder.group({
      selected: [undefined],
    });
  }

  /**
   * Returns a sorted list of episodes without the given id.
   *
   * @param episodes Episode[]
   * @param id number
   * @returns Episode[]
   */
  protected applyFilter(episodes: Episode[], id: number): Episode[] {
    const sortedEpisodes = _sortBy(episodes, 'f0_num');

    return sortedEpisodes.filter((episode: Episode) => episode.id !== id);
  }
}
