import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Country,  ProductType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isObject as _isObject } from 'lodash';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { LocaleForm } from '../../models/locale/form/locale-form.model';
import { Title } from 'app/modules/title/models/title.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { AutoTranslateService } from '../../services/auto-translate.service';
import { StormServiceResponseSingle } from '../../services/storm-service-response-single';
import { JobStatusDetails } from '../../models/job-status/job-status-details.model';
import { LocaleService } from '../../services/locale.service';


@Component({
  selector: 'bolt-autotranslate-metadata',
  template: require('./bolt-autotranslate-metadata.html'),
  styles: [require('../bolt-autopopulate-metadata/bolt-autopopulate-metadata.scss')],
})
export class BoltAutoTranslateMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() show: boolean;
  @Input() localization: Title;
  @Output() closeEvent: EventEmitter<any>;
  @Output() autoTranslateEvent: EventEmitter<any>;
  @ViewChild('autoTranslateModalRef') modal: ModalDirective;
  @ViewChild('confirmAutoTranslateRef') confirmModal: NgbModalRef;

  autoTranslateForm: FormGroup;
  isSaving: boolean;
  isDoubleChecked: boolean;
  subscriptions: Subscription;
  hasShowDoubleCheck: boolean;
  hasDisplayProcessingSpinner: boolean;
  selectedProperties: string[];

  constructor(
    protected formBuilder: FormBuilder,
    protected listLayoutProvider: ListLayoutProvider,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected autoTranslateService: AutoTranslateService,
    protected localeService: LocaleService
  ) {
    this.closeEvent = new EventEmitter<any>();
    this.autoTranslateEvent = new EventEmitter<JobStatusDetails>();
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
        this.initialize();
      }
    }
  }

  ngOnDestroy() {
    this.autoTranslateForm.reset();
    this.subscriptions.unsubscribe();
  }

  hasBlock(): boolean {
    return this.autoTranslateForm.invalid || this.selectedProperties.length < 1 || this.isSaving;
  }

  getLocaleForm(): LocaleForm {
    return this.autoTranslateForm.get('localeForm') as LocaleForm;
  }

  autoTranslate(): void {
    this.isSaving = true;

    const data = {
      titleId: this.localization.id,
      titleType: this.localization.type.toString().toLowerCase(),
      targetLocales: this.getLocales(),
      properties: this.selectedProperties,
    };

    const subs: Subscription = this.autoTranslateService.autoTranslate(data).subscribe(
      (response: StormServiceResponseSingle) => {
        try {
          const jobDetail = new JobStatusDetails(response.item);
          this.autoTranslateEvent.emit(jobDetail);
          this.isSaving = false;
          this.autoTranslateForm.reset();
          this.modalService.dismissAll();
          this.modal.hide();
        } catch (error) {
          this.notificationService.handleError('There was an error trying to auto-translate this title.', error);
          this.isSaving = false;
          this.modalService.dismissAll();
          this.close();
        }
      },
      (error) => {
        this.notificationService.handleError('There was an error trying to auto-translate this title.', error);
        this.isSaving = false;
        this.modalService.dismissAll();
        this.close();
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Closes this modal.
   *
   * @returns void
   */
  close(): void {
    this.closeEvent.emit('');
    this.modal.hide();
    this.autoTranslateForm.reset();
  }

  /**
   * Opens this modal.
   *
   * @returns void
   */
  open(): void {
    this.modal.show();
  }

  /**
   * Updates the properties selected with the emitted value
   * @param properties string[]
   * @returns void
   */
  setSelection(metadataFieldsForm: any): void {
    this.selectedProperties = metadataFieldsForm.properties;
  }


  /**
   * Opens the confirmation dialog.
   *
   * @returns void
   */
  showConfirmationDialog(): void { {
      this.isDoubleChecked = false;
      this.hasShowDoubleCheck = false;
      this.modalService.open(this.confirmModal);

      setTimeout(
        () => {
          this.hasShowDoubleCheck = true;
        },
        0
      );

    }
  }

  toggleDoubleChecked(): void {
    this.isDoubleChecked = !this.isDoubleChecked;
  }

  getLocalesSize(): number {
    const { language, territory, productType, account } = this.getLocaleForm().toJson();

    const size = this.localeService.getLocalesSize(language, territory, productType, account);

    return size;
  }

  getFormattedAccountNamesText(): string {
    const accounts = this.getLocaleForm().toJson().account.map(
      (id: number) => this.listLayoutProvider.getAccountById(id).name
    );
    if (accounts.length > 1) {
      return `${accounts.slice(0, -1).join(', ')} and ${accounts[accounts.length - 1]} accounts`;
    }
    return `${accounts} accounts`;
  }

  /**
   * Returns a list of the locales selected
   *
   * @returns string[]
   */
  protected getLocales(): string[] {
    const { language, territory, productType, account } = this.getLocaleForm().toJson();

    const languages = language.map(
      (id: number) => this.listLayoutProvider.getLanguageById(id)
    );

    const locales: string[] = this.localeService.getLocales(
      languages, territory, productType, account
    );

    return locales;
  }

  protected initialize(): void {
    this.selectedProperties = [];
    this.subscriptions = new Subscription();
    this.autoTranslateForm = this.formBuilder.group({
      localeForm: new LocaleForm(),
    });

    (this.autoTranslateForm.get('localeForm') as LocaleForm).getTerritoryField().setValue([Country.ALL_ID]);
    (this.autoTranslateForm.get('localeForm') as LocaleForm).getProductTypeField().setValue([ProductType.ALL_ID]);
  }
}
