import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { isUndefined as _isUndefined } from 'lodash';
import { Title } from 'app/modules/title/models/title.model';
import { EpisodeAttributes } from 'app/modules/title/models/title/episode/episode-attributes.enum';
import { FeatureAttributes } from 'app/modules/title/models/title/feature/feature-attributes.enum';
import { TitleAttributes } from 'app/modules/title/models/title/title-attributes.enum';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'bolt-autotranslate-metadata-field',
  template: require('./bolt-autotranslate-metadata-field.html'),
  styles: [require('./bolt-autotranslate-metadata-field.scss')]
})

export class BoltAutoTranslateMetadataFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() localization: Title;
  @Output('selected') selectEvent: EventEmitter<string[]>;

  metadataFieldsForm: FormGroup;

  protected languageFieldLimits: any;
  protected readonly titleAttributes: typeof TitleAttributes = TitleAttributes;
  protected readonly featureAttributes: typeof FeatureAttributes = FeatureAttributes;
  protected readonly episodeAttributes: typeof EpisodeAttributes = EpisodeAttributes;

  constructor(
    protected configService: ConfigService,
    protected formBuilder: FormBuilder,
  ) {
    this.selectEvent = new EventEmitter();
    this.languageFieldLimits = this.configService.get('title.languageLocalization.fields');
  }

  ngOnInit(): void {
    this.metadataFieldsForm = this.formBuilder.group({
      properties: []
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnDestroy(): void {

  }

  /**
   * Indicates if the given fieldName is empty valued.
   *
   * @param fieldName string
   * @returns boolean
   */
  isFieldEmpty(fieldName: string): boolean {
    const hasIt: boolean = _isUndefined(this.localization.localizedFields[fieldName]);

    return hasIt;
  }

  /**
   * Emits an updated metadataFieldsForm value
   *
   * @returns void
   */
  emitMetadataFieldsForm(): void {
    this.selectEvent.emit(this.metadataFieldsForm.value);
  }
}
