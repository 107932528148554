import { Component } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language } from '@bolt/ui-shared/master-data';
import { isObject as _isObject, isString as _isString, isUndefined as _isUndefined } from 'lodash';

import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { BoltTitleSpecificLocalizationDetailsComponent } from '../bolt-title-specific-localization-details/bolt-title-specific-localization-details.component';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';


@Component({
  selector: 'bolt-title-language-localization-details',
  template: require('./bolt-title-language-localization-details.component.html'),
  styles: [require('./bolt-title-language-localization-details.scss')]
})
export class BoltTitleLanguageLocalizationDetailsComponent extends BoltTitleSpecificLocalizationDetailsComponent {
  protected languageFieldLimits: any;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listProvider: StormListsProvider,
    protected configService: ConfigService,
    protected masterdataManager: MasterDataManager,
    protected notificationService: NotificationService,
  ) {
    super(appConfigurationManager, listProvider);
    this.languageFieldLimits = this.configService.get('title.languageLocalization.fields');
  }

  /**
   * Indicates if it has to display the alternative title field.
   *
   * @returns boolean
   */
  hasDisplayAltTitleField(): boolean {
    const itHas: boolean =
      Language.isJapanese((<Language>this.localization.language).id) &&
      this.localization.localeObject.isLanguageRoot();

    return itHas;
  }

  /**
   * Checks if the attribute info message should be displayed
   *
   * @param attribute string
   * @returns boolean
   */
  hasShowMessage(attribute: string): boolean {
    const hasIt = this.isFromPmx(attribute) || this.hasDisplayComputedMessage(attribute) || this.isAutoTranslated(attribute);
    return hasIt;
  }

  /**
   * Get the attribute info message text
   *
   * @param attribute string
   * @returns string
   */
  getFieldMessage(attribute: string): string {
    if (this.isFromPmx(attribute)) {
      return 'Received from PMX';
    } else if (this.hasDisplayComputedMessage(attribute)) {
      return this.getComputedFromMessage(attribute);
    } else if (this.isAutoTranslated(attribute)) {
      return 'AI Generated';
    } else {
      return '';
    }
  }

  /**
   * Indicates if the given attribute is from pmx.
   *
   * @param attribute string
   * @returns boolean
   */
  isFromPmx(attribute: string): boolean {
    // TODO update this logic when expect more services instead of only PMX
    const itIs: boolean =
      _isObject(this.localization.bpiField) &&
      _isString(this.localization.bpiField[attribute]) &&
      this.localization.bpiField[attribute].split('_')[0] === 'PMX';

    return itIs;
  }

  /**
   * Copies the given keywords to the clipboard.
   *
   * @param keywords string[]
   * @returns void
   */
  copyToClipboard(keywords: string[]): void {
    navigator.clipboard.writeText(keywords.join(',') + ',');
    this.notificationService.handleNotice(
      `Keywords successfully copied.`
    );
  }

  /**
   * Checks if the given field is auto translated
   *
   * @param attribute string
   * @returns boolean
   */
  protected isAutoTranslated(attribute: string): boolean {
    const isIt = this.localization?.translatedFields?.includes(attribute);
    return isIt;
  }
}
