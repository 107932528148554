import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language, List, ProductType, TypeEnum as ListTypeEnum } from '@bolt/ui-shared/master-data';
import { isArray as _isArray } from 'lodash';
import { Subscription } from 'rxjs';

import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { TitleAttributes } from 'app/modules/title/models/title/title-attributes.enum';
import { Locale } from '../../models/locale/locale.model';
import { LocalizationListHelper } from '../../helpers/localization-list.helper';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { StormComponent } from '../../models/storm-component.model';
import { AutopopulateService } from '../../services/autopopulate.service';
import { AutoTranslateService } from '../../services/auto-translate.service';


@Component({
  selector: 'bolt-locale-field',
  template: require('./bolt-locale-field.component.html'),
  styles: [require('./bolt-locale-field.scss')]
})
export class BoltLocaleFieldComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() readOnly: boolean;
  @Input() locale: Locale;
  @Input() showErrors: boolean;
  @Input() isAutopopulate: boolean = false;
  @Input() isAutoTranslate: boolean = false;
  @Output('languageChanged') languageChangedEvent: EventEmitter<number>;
  @Output('territoryChanged') territoryChangedEvent: EventEmitter<number[]>;
  @Output('productTypeChanged') productTypeChangedEvent: EventEmitter<number[]>;
  @Output('accountChanged') accountChangedEvent: EventEmitter<number[]>;

  protected languages: SelectionItem[];
  protected territories: SelectionItem[];
  protected productTypes: SelectionItem[];
  protected accounts: SelectionItem[];
  protected loadingLanguages: boolean;
  protected loadingTerritories: boolean;
  protected loadingProductTypes: boolean;
  protected loadingAccounts: boolean;
  protected titleAttributes: any = TitleAttributes;
  protected subscriptions: Subscription;
  protected readonly territoryAllId: number = Country.ALL_ID;
  protected readonly productAllId: number = ProductType.ALL_ID;
  protected readonly accountAllId: number = Account.ALL_ID;
  protected readonly accountDisneySvod: number = Account.DISNEYSVOD_ID;

  constructor(
    protected masterDataManager: MasterDataManager,
    protected appConfig: AppConfigProvider,
    protected entityMapperHelper: EntityMapperHelper,
    protected autopopulateService: AutopopulateService,
    protected autoTranslateService: AutoTranslateService
  ) {
    super();
    this.readOnly = false;
    this.showErrors = true;
    this.loadingLanguages = true;
    this.loadingTerritories = true;
    this.loadingProductTypes = true;
    this.loadingAccounts = true;
    this.subscriptions = new Subscription();
    this.languageChangedEvent = new EventEmitter();
    this.territoryChangedEvent = new EventEmitter();
    this.productTypeChangedEvent = new EventEmitter();
    this.accountChangedEvent = new EventEmitter();
  }

  ngOnInit() {
    this.loadLists();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Returns the current locale accounts.
   *
   * @returns Account[]
   */
  getAccounts(): Account[] {
    return this.accounts.filter(
      (item: SelectionItem) => this.locale.account.includes(item.value)
    ).map(
      (item: SelectionItem) => item.source
    );
  }

  /**
   * Returns the current locale Language.
   *
   * @returns Language
   */
  getLanguage(): Language {
    return this.languages.filter(
      (item: SelectionItem) => this.locale.language === item.value
    ).map(
      (item: SelectionItem) => item.source
    )[0];
  }

  /**
   * Returns the current locale product type.
   *
   * @returns ProductType[]
   */
  getProductTypes(): ProductType[] {
    return this.productTypes.filter(
      (item: SelectionItem) => this.locale.productType.includes(item.value)
    ).map(
      (item: SelectionItem) => item.source
    );
  }

  /**
   * Returns the current locale territories.
   *
   * @returns Country[]
   */
  getTerritories(): Country[] {
    return this.territories.filter(
      (item: SelectionItem) => this.locale.territory.includes(item.value)
    ).map(
      (item: SelectionItem) => item.source
    );
  }

  /**
   * Checks the given form values, in order to set only All value.
   *
   * @param formControl FormControl
   * @param event any
   * @param allValue number
   * @param shouldCheckGroups boolean
   * @param attribute TitleAttributes
   * @returns void
   */
  protected checkFormValues(
    formControl: FormControl,
    event: any,
    allValue: number,
    shouldCheckGroups: boolean = false,
    attribute: TitleAttributes
  ): void {
    let newSelection: number[];

    if (shouldCheckGroups) {
      newSelection = LocalizationListHelper.validateUniqueValuesWithGroups(formControl.value, event, allValue);
    } else {
      newSelection = LocalizationListHelper.validateUniqueValues(formControl.value, event[event.length - 1], allValue);
    }

    formControl.setValue(newSelection);

    switch (attribute) {
      case TitleAttributes.territory:
        this.emitTerritoryChange(newSelection);
      break;
      case TitleAttributes.productType:
        this.emitProductTypeChange(newSelection);
      break;
      case TitleAttributes.account:
        this.emitAccountChange(newSelection);
      break;
      default:
        throw new HttpError('Invalid attribute for checking locale form values');
    }
  }

  /**
   * Emits a language change.
   *
   * @param event number
   */
  protected emitLanguageChange(event: number): void {
    this.languageChangedEvent.emit(event);
  }

  /**
   * Emits a territory change.
   *
   * @param event number[]
   */
   protected emitTerritoryChange(event: number[]): void {
    this.territoryChangedEvent.emit(event);
  }

  /**
   * Emits a product type change.
   *
   * @param event number[]
   */
   protected emitProductTypeChange(event: number[]): void {
    this.productTypeChangedEvent.emit(event);
  }

  /**
   * Emits a account change.
   *
   * @param event number[]
   */
   protected emitAccountChange(event: number[]): void {
    this.accountChangedEvent.emit(event);
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  protected hasLanguages(): boolean {
    return _isArray(this.languages) && this.languages.length > 0;
  }

  /**
   * Indicates if it has territories.
   *
   * @returns boolean
   */
   protected hasTerritories(): boolean {
    return _isArray(this.territories) && this.territories.length > 0;
  }

  /**
   * Indicates if it has product types.
   *
   * @returns boolean
   */
   protected hasProductTypes(): boolean {
    return _isArray(this.productTypes) && this.productTypes.length > 0;
  }

  /**
   * Indicates if it has accounts.
   *
   * @returns boolean
   */
   protected hasAccounts(): boolean {
    return _isArray(this.accounts) && this.accounts.length > 0;
  }

  /**
   * Loads the metadata lists.
   *
   * @returns void
   */
  private loadLists(): void {
    this.loadLanguages();
    this.loadTerritories();
    this.loadProductTypes();
    this.loadAccounts();
  }

  /**
   * Loads the masterdata languages.
   *
   * @returns void
   */
  private loadLanguages(): void {
    this.loadingLanguages = true;

    if (this.isAutopopulate) {
      this.subscriptions.add(
        this.autopopulateService.getAutopopulateLanguages().subscribe(
          (list: Language[]) => {
            let items: SelectionItem[];

            items = list?.map((language: Language) => new SelectionItem(language.name, language.id));


            this.languages = items;
            this.loadingLanguages = false;
          }
        )
      );
    } else if (this.isAutoTranslate) {
      this.subscriptions.add(
        this.autoTranslateService.getAutoTranslatedLanguages().subscribe(
          (list: Language[]) => {
            let items: SelectionItem[];

            items = list?.map((language: Language) => new SelectionItem(language.name, language.id));


            this.languages = items;
            this.loadingLanguages = false;
          }
        )
      );
    } else {
      this.subscriptions.add(
        this.masterDataManager.getListForAsObservable(ListTypeEnum.language).subscribe(
          (list: List) => {
            let items: SelectionItem[];

            if (this.locale?.type.isLanguage()) {
              items = list.items.filter((item: SelectionItem) => item.value !== Language.ALL_ID);
            } else {
              items = list.items;
            }

            this.languages = items;
            this.loadingLanguages = false;
          }
        )
      );
    }
  }

  /**
   * Loads the masterdata territories.
   *
   * @returns void
   */
  private loadTerritories(): void {
    this.loadingTerritories = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(ListTypeEnum.territory).subscribe(
        (list: List) => {
          let items: SelectionItem[];

          if (this.locale?.type.isTerritory()) {
            items = list.items.filter((item: SelectionItem) => item.value !== this.territoryAllId);
          } else {
            items = list.items;
          }

          this.territories = items;
          this.loadingTerritories = false;
        }
      )
    );
  }

  /**
   * Loads the masterdata productTypes.
   *
   * @returns void
   */
  private loadProductTypes(): void {
    this.loadingProductTypes = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(ListTypeEnum.productType).subscribe(
        (list: List) => {
          this.productTypes = list.items;
          this.loadingProductTypes = false;
        }
      )
    );
  }

  /**
   * Loads the masterdata accounts.
   *
   * @returns void
   */
  private loadAccounts(): void {
    this.loadingAccounts = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(ListTypeEnum.account).subscribe(
        (list: List) => {
          let items: SelectionItem[];

          if (this.locale?.type.isAccount()) {
            items = list.items.filter((item: SelectionItem) => item.value !== this.accountAllId);
          } else {
            if (this.isAutopopulate || this.isAutoTranslate) {
              items = list.items.filter((item: SelectionItem) => item.value === this.accountAllId || item.value === this.accountDisneySvod);
            } else {
              items = list.items;
            }
          }

          this.accounts = items;
          this.loadingAccounts = false;
        }
      )
    );
  }
}
