import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isUndefined as _isUndefined } from 'lodash';

import { FeedbackQuestionBase } from '../../models/feedback-question.model';


@Component({
  selector: 'bolt-autotranslate-feedback-question',
  template: require('./bolt-autotranslate-feedback-question.html'),
  styles: [require('./bolt-autotranslate-feedback-question.scss')]
})
export class BoltAutoTranslateFeedbackQuestionComponent {
  @Input() form: FormGroup;
  @Input() question: FeedbackQuestionBase<any>;

  freeTextLimitSize = 100;

  constructor() { }
}
