import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  isUndefined as _isUndefined, isObject as _isObject, isString as _isString, isEmpty as _isEmpty
} from 'lodash';

import { Title } from 'app/modules/title/models/title.model';
import { FeedbackService } from '../../services/feedback.service';
import { NotificationService } from '@bolt/ui-shared/notification';
import { HttpError } from '@bolt/ui-shared/common';
import { TitleService } from 'app/modules/title/services/title.service';


@Component({
  selector: 'bolt-autotranslate-feedback-dialog',
  template: require('./bolt-autotranslate-feedback-dialog.html'),
  styles: [require('./bolt-autotranslate-feedback-dialog.scss')]
})
export class BoltAutoTranslateFeedbackDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: Title;
  @Input() show: boolean;
  @Output() onSave = new EventEmitter<void>();
  @ViewChild('feedbackModalRef') modal: ModalDirective;

  feedbackFieldsForm: FormGroup;
  freeTextLimitSize = 100;
  questions: any[];
  isSaving: boolean;

  constructor(
    protected feedbackService: FeedbackService,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) { }

  ngOnInit(): void {
    this.getFormFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
        this.getFormFields();
      }
    }
  }

  ngOnDestroy() {
    this.feedbackFieldsForm.reset();
  }

  /**
   * Returns the info message that explain the conditions to be able to save the feedback
   *
   * @returns string
   */
  getTooltipMessage(): string {
    const message = 'Please, complete the form to save your changes, and improve translation quality. Select at least one option, or use the "Other" section if none apply.';

    return message;
  }

  /**
   * Get the form fields for the pop-up
   *
   * @returns void
   */
  getFormFields(): void {
    this.questions = this.feedbackService.getAutoTranslateFeedbackFields();
    this.feedbackFieldsForm = this.feedbackService.toFormGroup(this.questions);
  }

  /**
   * Checks if at leas one field has a value
   *
   * @returns boolean
   */
  hasBlock(): boolean {
    const feedback = this.feedbackFieldsForm.value;
    const hasBlock = !this.questions.some(({ field }) => {
      const value = feedback[field];
      const hasValue = _isString(value) ? !_isEmpty(value.trim()) : value;

      return hasValue;
    });

    return hasBlock;
  }

  /**
   * Saves the feedback form values
   *
   * @returns void
   */
  save(): void {
    if (this.feedbackFieldsForm.invalid) {
      this.feedbackFieldsForm.markAllAsTouched();
      return;
    }

    this.isSaving = true;

    const answers = this.getAnswers(this.feedbackFieldsForm.value);

    this.titleService.fetchLocalized(
      this.title.id,
      this.title.type,
      this.title.locale,
      (localizedTitle: Title) => this.saveLocalizedFeedback(localizedTitle, answers),
      (error: HttpError) => this.notificationService.handleError('The feedback validation has failed.', error)
    );
  }

  saveLocalizedFeedback(localizedTitle: Title, answers: string[]): void {
    const feedbackRequest = {
      entityId: localizedTitle.id,
      entityType: localizedTitle.type,
      feedback: answers
    };

    this.feedbackService.saveFeedback(
      feedbackRequest
    ).subscribe(
      () => {
        this.notificationService.handleNotice('The provided feedback was saved.');
        this.feedbackFieldsForm.reset();
        this.onSave.emit();
      },
      (error) => {
        this.notificationService.handleError('There was an error while saving the feedback.', error);
      },
      () => this.isSaving = false
    );
  }

  /**
   * Opens this modal.
   *
   * @returns void
   */
  open(): void {
    this.modal.show();
  }

  /**
   * Opens this modal.
   *
   * @returns void
   */
  close(): void {
    this.modal.hide();
  }

  /**
   * Maps the form feedback value into the feedback request
   *
   * @param feedback any
   * @returns string[]
   */
  protected getAnswers(feedback: any): string[] {
    const answers = [];

    this.questions.forEach(({ field }) => {
      const value = feedback[field];
      let mappedValue: string;

      if (_isString(value)) {
        mappedValue = `${field}:${value.trim()}`;
      } else if (value) {
        mappedValue = field;
      } else {
        return;
      }

      answers.push(mappedValue);
    });

    return answers;
  }
}
