import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';

import {
  isBoolean as _isBoolean, isNull as _isNull, isNumber as _isNumber, isObject as _isObject, isUndefined as _isUndefined
} from 'lodash';

import { LocalizedInterface, Localized } from 'app/modules/common/models/localized.model';
import { TitleMetadataInterface } from './title-metadata.model';


// TODO Move this to a separated file.
export enum TitleType {
  feature = <any>'feature',
  series = <any>'series',
  season = <any>'season',
  episode = <any>'episode'
}

/**
 * @deprecated 2021-07-12: Use `Title` instead of this interface.
 */
export interface TitleInterface extends LocalizedInterface {
  altTitle?: string;
  aka: string[];
  bpiField?: boolean;
  disneyPlusOriginal?: boolean;
  disneyPlusSelected?: boolean;
  eidr?: string;
  fka: string[];
  fullTitle?: string;
  isanId?: string;
  itemsLockingStatus?: LockingStatus;
  legalTitle?: string;
  moratorium?: boolean;
  originalSpokenLanguageId?: number;
  originalTitle?: string;
  prettifiedLegalTitle?: string;
  productMasterClass?: string;
  productMasterSubclass?: string;
  productMasterColor?: string;
  productMasterCreativeFormat?: string;
  productMasterTitle?: string;
  productMasterType?: string;
  radarProductId?: number;
  title?: string;
  type?: TitleType;

  isEpisode?(): boolean;
  isFeature?(): boolean;
  isPersisted?(): boolean;
  isSeason?(): boolean;
  isSeries?(): boolean;
}

/**
 * @todo We have to re-implement the whole `Title` hierarchy class in order to not use Typescript set/get with underscore attribute. But
 * because this is part of the Bolt's core model, we need a tech debt story for doing this and move it to bolt-ui-shared project.
 * @see `TitleAttributes`: Ensure that enumerative and this class are synchronized about public attributes.
 */
export class Title extends Localized implements TitleInterface {
  protected _altTitle: string;
  protected _aka: string[];
  protected _bpiField: any;
  protected _disneyPlusOriginal: boolean;
  protected _disneyPlusSelected: boolean;
  protected _eidr: string;
  protected _fullTitle: string;
  protected _fka: string[];
  protected _isanId: string;
  protected _itemsLockingStatus: LockingStatus;
  protected _legalTitle: string;
  protected _originalSpokenLanguageId: number;
  protected _originalTitle: string;
  protected _moratorium: boolean;
  protected _prettifiedLegalTitle: string;
  protected _productMasterClass: string;
  protected _productMasterSubclass: string;
  protected _productMasterColor: string;
  protected _productMasterCreativeFormat: string;
  protected _productMasterTitle: string;
  protected _productMasterType: string;
  protected _radarProductId: number;
  protected _title: string;
  protected _type: TitleType;
  protected _apolloRating: boolean;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setItemsLockingStatus(attributes);
  }

  /**
   * Get the attributes from PMX.
   *
   * @returns string[]
   */
  static getPmxAttributes(): string[] {
    return ['keywords', 'shortSynopsis', 'mediumSynopsis', 'fullSynopsis'];
  }

  get altTitle(): string {
    return this._altTitle;
  }

  set altTitle(altTitle: string) {
    this._altTitle = altTitle;
  }

  get aka(): string[] {
    return this._aka;
  }

  set aka(aka: string[]) {
    this._aka = aka;
  }

  get disneyPlusOriginal(): boolean {
    return this._disneyPlusOriginal;
  }

  set disneyPlusOriginal(value: boolean) {
    this._disneyPlusOriginal = value;
  }

  get disneyPlusSelected(): boolean {
    return this._disneyPlusSelected;
  }

  set disneyPlusSelected(value: boolean) {
    this._disneyPlusSelected = value;
  }

  get fullTitle(): string {
    return this._fullTitle;
  }

  set fullTitle(fullTitle: string) {
    this._fullTitle = fullTitle;
  }

  get fka(): string[] {
    return this._fka;
  }

  set fka(fka: string[]) {
    this._fka = fka;
  }

  get isanId(): string {
    return this._isanId;
  }

  set isanId(value: string) {
    this._isanId = value;
  }

  get itemsLockingStatus(): LockingStatus {
    return this._itemsLockingStatus;
  }

  set itemsLockingStatus(lockingStatus: LockingStatus) {
    this._itemsLockingStatus = lockingStatus;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  set legalTitle(legalTitle: string) {
    this._legalTitle = legalTitle;
  }

  get moratorium(): boolean {
    return this._moratorium;
  }

  set moratorium(moratorium: boolean) {
    if (_isBoolean(moratorium)) {
      this._moratorium = moratorium;
    } else if (_isNull(moratorium) || _isUndefined(moratorium)) {
      this._moratorium = false;
    } else {
      throw new Error(`${this.type}: Invalid value given for a moratorium flag: ${moratorium}`);
    }
  }

  get originalSpokenLanguageId(): number {
    return this._originalSpokenLanguageId;
  }

  set originalSpokenLanguageId(id: number) {
    this._originalSpokenLanguageId = id;
  }

  get type(): TitleType {
    return this._type;
  }

  set type(type: TitleType) {
    this._type = type;
  }

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
  }

  get eidr(): string {
    return this._eidr;
  }

  set eidr(eidr: string) {
    this._eidr = eidr;
  }

  get localizations(): TitleMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: TitleMetadataInterface[]) {
    throw new Error(this.getEntityName() + ' :: localizations setter needs to be implemented');
  }

  get originalTitle(): string {
    return this._originalTitle;
  }

  set originalTitle(originalTitle: string) {
    this._originalTitle = originalTitle;
  }

  get bpiField(): any {
    return this._bpiField;
  }

  set bpiField(value: any) {
    this._bpiField = value;
  }

  get prettifiedLegalTitle(): string {
    return this._prettifiedLegalTitle;
  }

  set prettifiedLegalTitle(prettifiedLegalTitle: string) {
    this._prettifiedLegalTitle = prettifiedLegalTitle;
  }

  get productMasterClass(): string {
    return this._productMasterClass;
  }

  set productMasterClass(value: string) {
    this._productMasterClass = value;
  }

  get productMasterSubclass(): string {
    return this._productMasterSubclass;
  }

  set productMasterSubclass(value: string) {
    this._productMasterSubclass = value;
  }

  get productMasterColor(): string {
    return this._productMasterColor;
  }

  set productMasterColor(value: string) {
    this._productMasterColor = value;
  }

  get productMasterCreativeFormat(): string {
    return this._productMasterCreativeFormat;
  }

  set productMasterCreativeFormat(value: string) {
    this._productMasterCreativeFormat = value;
  }

  get productMasterTitle(): string {
    return this._productMasterTitle;
  }

  set productMasterTitle(value: string) {
    this._productMasterTitle = value;
  }

  get productMasterType(): string {
    return this._productMasterType;
  }

  set productMasterType(value: string) {
    this._productMasterType = value;
  }

  get radarProductId(): number {
    return this._radarProductId;
  }

  set radarProductId(id: number) {
    this._radarProductId = id;
  }

  get apolloRating(): boolean {
    return this._apolloRating;
  }

  set apolloRating(value: boolean) {
    this._apolloRating = value;
  }

  /**
   * Returns an object with raw-formatted data.
   *
   * @returns Object
   */
  getRawObject(): object {
    const names = Object.getOwnPropertyNames(Title.prototype);
    const object = { };

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Title.prototype, name);
      return !!result.get;
    });

    getters.forEach((key) => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    const rawObject = Object.assign({ }, super.getRawObject(), object);

    return rawObject;
  }

  /**
   * Indicates if it is an episode.
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    const isIt: boolean = this.type === TitleType.episode;
    return isIt;
  }

  /**
   * Indicates if it is a feature.
   *
   * @returns void
   */
  isFeature(): boolean {
    const isIt: boolean = this.type === TitleType.feature;
    return isIt;
  }

  /**
   * Indicates if it is persisted.
   *
   * @returns boolean
   */
  isPersisted(): boolean {
    const isIt: boolean = _isNumber(this.id);
    return isIt;
  }

  /**
   * Indicates if it is a season.
   *
   * @returns boolean
   */
  isSeason(): boolean {
    const isIt: boolean = this.type === TitleType.season;
    return isIt;
  }

  /**
   * Indicates if it is a series.
   *
   * @returns boolean
   */
  isSeries(): boolean {
    const isIt: boolean = this.type === TitleType.series;
    return isIt;
  }

  /**
   * Indicates if the given field is a computed one by root.
   *
   * @param field string
   */
   isNonEmptyRootField(field: string): boolean {
    const isIt: boolean = !_isUndefined(this[field]) && _isUndefined(this.localizedFields[field]);
    return isIt;
  }

  /**
   * Set the items locking status field with the given data.
   *
   * @param data object
   * @returns void
   */
  protected setItemsLockingStatus(data: object): void {
    if (_isObject(data)) {
      this._itemsLockingStatus = LockingStatus.newFromItemsLockStatus(data);
    }
  }

  /**
   * Gets the same value if it's not undefined otherwise null
   *
   * @param value any
   * @returns any | null
   */
  protected getDefinedValueOrNull(value: any): any | null {
    return _isUndefined(value) ? null : value;
  }
}
