import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { sortBy as _sortBy } from 'lodash';
import { Observable } from 'rxjs';

import { BoltAbstractService } from './bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { FeedbackQuestionBase } from '../models/feedback-question.model';
import { Title } from 'app/modules/title/models/title.model';
import { StormServiceResponseSingle } from './storm-service-response-single';


@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends BoltAbstractService {

  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns the list of feedback questions for AI translation
   *
   * @returns any[]
   */
  getAutoTranslateFeedbackFields(): any[] {
    const feedbackFields = [
      new FeedbackQuestionBase({
          field: 'awkwardTranslation',
          order: 1,
          label: 'Awkward or Unidiomatic Translation',
          description: 'Is the translated synopsis easy to understand for a native speaker of the target language, with clear sentence structure and appropriate vocabulary',
          type: 'checkbox',
          value: false
      }),
      new FeedbackQuestionBase({
        field: 'offensiveTranslation',
        order: 2,
        label: 'Offensive Translation',
        description: 'Harassment , Hate speech, Sexually explicit/slangs/vulgarities or Dangerous content',
        type: 'checkbox',
        value: false
      }),
      new FeedbackQuestionBase({
        field: 'spellingGrammarPunctuation',
        order: 3,
        label: 'Spelling, Grammar and/or Punctuation',
        description: 'Is the translated synopsis free from grammatical errors and typos?',
        type: 'checkbox',
        value: false
      }),
      new FeedbackQuestionBase({
        field: 'redundantMissingTranslation',
        order: 4,
        label: 'Redundant or Missing Translation',
        type: 'checkbox',
        value: false
      }),
      new FeedbackQuestionBase({
        field: 'badToneStyle',
        order: 5,
        label: 'Bad Tone and/or style',
        description: 'Does the translation accurately reflect the tone of the original synopsis, whether it be serious, humorous, suspenseful, Creative etc., and is the style appropriate for the genre?',
        type: 'checkbox',
        value: false
      }),
      new FeedbackQuestionBase({
        field: 'other',
        order: 6,
        label: 'Other',
        description: 'Please comment on any other issues you encountered while reviewing this translation e.g. title was inaccurate, wrong Keywords etc.',
        placeholder: 'Enter your comments here.  If none of the above options was selected, this field will be mandatory.',
        type: 'text',
        value: ''
      }),
    ];

    return feedbackFields;
  }

  /**
   * Given a list of feedback questions returns a Form Group for those questions
   *
   * @param questions any[]
   * @returns FormGroup
   */
  toFormGroup(questions: any[]): FormGroup {
    const group: any = { };

    questions.forEach(
      question => {
        group[question.field] = new FormControl();
      }
    );

    return new FormGroup(group);
  }

  /**
   * Saves a given Feedback on the DB
   *
   * @param feedback any
   * @returns Observable<any>
   */
  saveFeedback(feedback: any): Observable<any> {
    const url: string = this.generateUrl('productService.feedback.save.endpoint');
    const body = JSON.stringify(feedback);

    return this.doPostRequestAsObservable({ url, body });
  }

  /**
   * Find all the feedback for a given title
   *
   * @param root Title
   * @returns Observable<any>
   */
  findByRoot(root: Title): Observable<any> {
    const url: string = this.generateUrl(
      'productService.feedback.findByRoot.endpoint', null, { rootId: root.id, titleType: root.type.toString().toLowerCase() }
    );

    return this.doGetRequestAsObservable({ url });
  }

  /**
   * Find the Feedback for a given localization
   *
   * @param localization Title
   * @returns Observable<StormServiceResponseSingle>
   */
  findByLocalization(localization: Title): Observable<StormServiceResponseSingle> {
    const url: string = this.generateUrl('productService.feedback.findByLocalization.endpoint', null, { localizationId: localization.id });

    return this.doGetRequestAsObservable({ url, nullable: true });
  }
}
