/**
 * Common attributes for all title types.
 *
 * @see `Title`: Ensure that class and this enumerative are synchronized about public attributes.
 */
export enum TitleAttributes {
  account = 'account',
  altTitle = 'altTitle',
  bpiField = 'bpiField',
  disneyPlusOriginal = 'disneyPlusOriginal',
  disneyPlusSelected = 'disneyPlusSelected',
  eidr = 'eidr',
  fullSynopsis = 'fullSynopsis',
  fullTitle = 'fullTitle',
  id = 'id',
  inheritedAttributes = 'inheritedAttributes',
  itemsLockingStatus = 'itemsLockingStatus',
  isanId = 'isanId',
  isMoratorium = 'isMoratorium',
  language = 'language',
  lastModified = 'lastModified',
  lastModifiedBy = 'lastModifiedBy',
  legalTitle = 'legalTitle',
  locale = 'locale',
  localeObject = 'localeObject',
  localizations = 'localizations',
  localized = 'localized',
  localizedFields = 'localizedFields',
  locked = 'locked',
  lockedBy = 'lockedBy',
  lockedDate = 'lockedDate',
  mediumSynopsis = 'mediumSynopsis',
  originalData = 'originalData',
  originalSpokenLanguageId = 'originalSpokenLanguageId',
  originalTitle = 'originalTitle',
  prettifiedLegalTitle = 'prettifiedLegalTitle',
  productMasterClass = 'productMasterClass',
  productMasterSubclass = 'productMasterSubclass',
  productMasterColor = 'productMasterColor',
  productMasterCreativeFormat = 'productMasterCreativeFormat',
  productMasterTitle = 'productMasterTitle',
  productMasterType = 'productMasterType',
  productType = 'productType',
  radarProductId = 'radarProductId',
  rootId = 'rootId',
  shortSynopsis = 'shortSynopsis',
  territory = 'territory',
  title = 'title',
  type = 'type'
}
