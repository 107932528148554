import { Injectable } from '@angular/core';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { Language } from '@bolt/ui-shared/master-data';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { sortBy as _sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BoltAbstractService } from './bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { StormServiceResponseSingle } from './storm-service-response-single';
import { CheckTypeEnum } from './check-type.enum';


@Injectable({
  providedIn: 'root'
})
export class AutoTranslateService extends BoltAbstractService {

  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Get the list of languages configured for auto-translate
   *
   * @returns Observable<Language[]>
   */
  getAutoTranslatedLanguages(): Observable<Language[]> {
    const queryParams = new UrlSearchParams();
    queryParams.set('autoTranslate', 'true');
    queryParams.set('_size', '1000');

    const url: string = this.generateUrl(
      'newMasterdata.fetch.language.autopopulate.endpoint',
      null,
      queryParams
    );

    const obs: Observable<Language[]> = this.doGetRequestAsObservable(
      { url: url, checkType: CheckTypeEnum.object }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          let languages: Language[] = new Array();

          languages = response.item.content.map(
            (item: any) => {
              const { id, name, alternateName, localeLanguage, languageType, iso6391 } = item;
              const language = new Language(id, name, alternateName, localeLanguage, languageType, iso6391);

              return language;
            }
          );

          return _sortBy(languages, [(lang: Language) => lang.name.toLowerCase()]);
        }
      )
    );

    return obs;
  }

  /**
   * Starts the process to auto-translate the given fields for the given title and locales
   *
   * @param data object
   * @returns Observable<StormServiceResponseSingle>
   */
  autoTranslate(
    data: {
      titleId: number,
      titleType: string,
      targetLocales: string[],
      properties: string[],
    }
  ): Observable<StormServiceResponseSingle> {
    const url: string = this.generateUrl(
      'productService.autoTranslate.endpoint'
    );
    const dataString = JSON.stringify(data);

    return this.doPostRequestAsObservable({
      url: url,
      body: dataString
    });
  }
}
