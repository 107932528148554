// TODO: This enumerative belongs only to bolt-ui project. It's candidate to be removed.
export enum ToggleKeyEnum {
  autoTranslateButtonOn = 'autoTranslateButtonOn',
  creditsDubbingBulkEntryWizardOn = 'creditsDubbingBulkEntryWizardOn',
  functionalMetadataAsFieldsOn = 'functionalMetadataAsFieldsOn',
  positionMultifieldSearchOn = 'positionMultifieldSearchOn',
  searchLocalizedAllLanguages = 'searchLocalizedAllLanguages',
  searchLocalizedEnglish = 'searchLocalizedEnglish',
  talentMultifieldSearchOn = 'talentMultifieldSearchOn',
}
