import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { MetadataSanitizer } from '@bolt/ui-shared/data-transform';
import { Account } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { FunctionalMetadataFormHandlerService, LevelModeEnum } from '@bolt/ui-shared/title';

import {
  isBoolean as _isBoolean, isObject as _isObject, isNull as _isNull, isString as _isString, isUndefined as _isUndefined,
  isArray as _isArray, cloneDeep as _cloneDeep, isEqual as _isEqual, intersection as _intersection
} from 'lodash';

import { forkJoin, Observable, Subscription } from 'rxjs';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { AccountLocalizationForm } from '../../models/localization/form/account/account-localization-form.model';
import { LanguageLocalizationForm } from '../../models/localization/form/language/language-localization-form.model';
import { TerritoryLocalizationForm } from '../../models/localization/form/territory/territory-localization-form.model';
import { TypeEnum } from 'app/modules/common/models/locale/type/type.enum';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { LocalizationManagerAction } from './localization-manager-action.enum';
import { EpisodeAttributes } from '../../models/title/episode/episode-attributes.enum';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { SeasonAttributes } from '../../models/title/season/season-attributes.enum';
import { SeriesAttributes } from '../../models/title/series/series-attributes.enum';
import { TitleService } from '../../services/title.service';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleFactory } from '../../helpers/title-factory/title-factory.helper';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { Time } from 'app/modules/common/models/time.model';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';
import { BoltRatingService } from '../../services/bolt-rating.service';
import { FeedbackService } from 'app/modules/common/services/feedback.service';
import { Title } from '../../models/title.model';


@Component({
  selector: 'bolt-title-localization-manager',
  template: require('./bolt-title-localization-manager.component.html'),
  styles: [require('./bolt-title-localization-manager.scss')]
})
export class BoltTitleLocalizationManagerComponent implements OnChanges, OnDestroy {
  @Input() attributes: TitleAttributes[] | EpisodeAttributes[] | FeatureAttributes[] | SeasonAttributes[] | SeriesAttributes[] | any[] = [];
  @Input() action: LocalizationManagerAction;
  @Input() customization: boolean = false;
  @Input() localeOnReadOnlyMode: boolean = false;
  @Input() metadataOnReadOnlyMode: boolean = false;
  @Input() originCustomizationLocale: Locale;
  @Input() show: boolean = false;
  @Input() title: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata;
  @Input() allowSaveAndLock: boolean = true;
  @Input() showUnifiedRatings: boolean = false;
  @Output('closed') closeEvent: EventEmitter<undefined> = new EventEmitter();
  @Output('saved') saveEvent: EventEmitter<EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata> = new EventEmitter();

  showFeedbackDialog: boolean;

  protected currentModal: NgbModalRef;
  protected currentLocale: string;
  protected form: LanguageLocalizationForm | TerritoryLocalizationForm | AccountLocalizationForm;
  protected languageFieldSpecs: any;
  protected lockRequired: boolean;
  protected isSaving: boolean = false;
  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected formConfig: FormConfigService,
    protected formHandlerService: FormHandlerService,
    protected functionalMetadataFormHandler: FunctionalMetadataFormHandlerService,
    protected modalService: NgbModal,
    protected titleService: TitleService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected notificationService: NotificationService,
    protected boltRatingService: BoltRatingService,
    protected feedbackService: FeedbackService
  ) {
    this.languageFieldSpecs = this.formConfig.get('title.languageLocalization.fields');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show) && changes.show.currentValue) {
      this.createForm();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroyForm();
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @param force boolean
   * @returns void
   */
  cancel(force: boolean, modal: any): void {
    if (force || this.form.pristine) {
      this.isSaving = false;
      this.show = false;
      this.closeEvent.emit();
      this.closeModal();
      this.destroyForm();

      return;
    }

    if (!this.form.pristine) {
      this.openModal(modal);
    }
  }

  /**
   * Close the current confirmation cancel modal.
   *
   * @param stopSaving boolean
   * @returns void
   */
  closeModal(stopSaving: boolean = false): void {
    if (stopSaving) {
      this.form.enable();
      this.isSaving = false;
    }

    if (!_isUndefined(this.currentModal)) {
      this.currentModal.close();
    }
  }

  /**
   * Retrieve the tooltip legend for the 'save' and 'save & lock' buttons
   *
   * @returns string
   */
  getTooltipLegend(): string {
    if (this.form.invalid) {
      return 'Please complete at least one of the above fields.';
    } else if (this.isCustomization() && this.hasSameLocale()) {
      return 'Cannot customize metadata into the same localization.';
    } else {
      return '';
    }
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  hasDisableSave(): boolean {
    const itHas: boolean =
      this.form.invalid ||
      (this.isCustomization() && this.hasSameLocale()) ||
      this.isSaving;

    return itHas;
  }

  /**
   * Indicates if it has to display the original language details section.
   *
   * @returns boolean
   */
  hasDisplayOriginalLanguageDetails(): boolean {
    return this.title.localeObject.type.isLanguage();
  }

  /**
   * Indicates if it has to display the save and lock button.
   *
   * @returns boolean
   */
  hasDisplaySaveAndLockButton(): boolean {
    return this.allowSaveAndLock;
  }

  /**
   * Validate that the selected custom locale is the same as the original.
   *
   * @returns boolean
   */
  hasSameLocale(): boolean {
    const locale: Locale = new Locale({
      language: this.form.getLanguageField().value,
      territory: this.form.getTerritoryField().value,
      productType: this.form.getProductTypeField().value,
      account: this.form.getAccountField().value,
    });

    return locale.getLocale() === this.originCustomizationLocale.getLocale();
  }

  /**
   * Indicates if the current side panel is adding.
   *
   * @returns boolean
   */
  isAdding(): boolean {
    return this.action === LocalizationManagerAction.create;
  }

  /**
   * Indicates if the current side panel is for a customization.
   *
   * @returns boolean
   */
  isCustomization(): boolean {
    return this.customization;
  }

  /**
   * Indicates if the current side panel is editing.
   *
   * @returns boolean
   */
  isEditing(): boolean {
    return this.action === LocalizationManagerAction.edit;
  }

  /**
   * Opens the given modal.
   *
   * @param modal any
   * @returns void
   */
  openModal(modal: any): void {
    this.currentModal = this.modalService.open(modal);
  }

  /**
   * Saves the current localization.
   *
   * @returns void
   */
  save(): void {
    this.closeModal();
    const data: any = this.prepareMetadata();
    const type: string = this.isCustomization() ? 'Customization' : 'Localization';
    const action: string = this.isEditing() ? 'edit' : 'add';
    const obs: Array<Observable<any>> = [];

    if (!this.localeOnReadOnlyMode && this.isEditing()) {
      const localeData: any = {
        language: this.form.getLanguageField().value,
        territory: this.form.getTerritoryField().value,
        productType: this.form.getProductTypeField().value,
        account: this.form.getAccountField().value
      };

      obs.push(this.titleService.updateLocale(this.title.type, this.title.id, this.title.locale, localeData));
    }

    if (!this.metadataOnReadOnlyMode) {
      obs.push(this.titleService.setProductMetadata(
        {
          productType: this.title.type,
          productId: this.title.id
        },
        this.currentLocale,
        TitleFactory(this.title.type.toString(), data)
      ));
    }

    this.subscriptions.add(
      forkJoin(obs).subscribe(
        (response: StormServiceResponseSingle[]) => {
          this.saveEvent.emit(response[response.length - 1].item);
          this.cancel(true, undefined);

          this.notificationService.handleNotice(
            `${type} successfully ${action}ed`,
            `for ${this.currentLocale}`
          );
        },
        (error: HttpError) => {
          this.notificationService.handleError(`Failed trying to ${action} the ${type}`, error);
          this.isSaving = false;
          this.form.enable();
        }
      )
    );
  }

  /**
   * Indicates if the Functional Metadata toggle is on.
   *
   * @returns boolean
   */
  hasFunctionalMetadataEnabled(): boolean {
    const hasIt = this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn);
    return hasIt;
  }

  /**
   * Starts the save process of the current form, if lockRequired is true locks the title.
   *
   * @param lockRequired boolean
   * @param modal any
   * @returns void
   */
  startSaveValidation(lockRequired: boolean, modal: any): void {
    if (this.shouldShowFeedbackDialog()) {
      if (this.validateAiChanges()) {
        this.titleService.fetchLocalized(
          this.title.id,
          this.title.type,
          this.title.locale,
          (localizedTitle: Title) => this.validateLocalizedFeedback(localizedTitle, lockRequired, modal),
          (error: HttpError) => this.notificationService.handleError('The feedback validation has failed.', error)
        );
      } else {
        this.validateForm(lockRequired, modal);
      }
    } else {
      this.validateForm(lockRequired, modal);
    }
  }

  validateLocalizedFeedback(localizedTitle: Title, lockRequired: boolean, modal: any): void {
    this.feedbackService.findByLocalization(localizedTitle).subscribe(
      (response: StormServiceResponseSingle) => {
        if (_isNull(response.item)) {

          if (lockRequired) {
            this.currentModal.dismiss();
           this.lockRequired = true;
          }

          this.showFeedbackDialog = true;
          this.lockRequired = lockRequired;
        } else {
          this.validateForm(lockRequired, modal);
        }
      },
      (error: HttpError) => {
        this.notificationService.handleError('The feedback validation has failed.', error);
      }
    );
  }

  /**
   * Checks if at least one auto-translated field was modified
   *
   * @returns boolean
   */
  validateAiChanges(): boolean {
    // Exclude title and keywords
    const translatedFields = this.title.translatedFields ? this.title.translatedFields.filter((field) => field !== 'title' && field !== 'keywords') : [];
    const autoTranslateFields = _intersection(this.attributes, translatedFields);
    const modifiedFields = [];

    autoTranslateFields.forEach(
      (field: string) => {
        const formControl = this.form.get(field);
        const titleValue = this.title[field];

        if (formControl && !_isEqual(formControl.value, titleValue)) {
          modifiedFields.push(field);
        }
      }
    );

    return modifiedFields.length > 0;
  }

  onFeedbackSaved() {
    this.validateForm(this.lockRequired, this.currentModal);
  }

  shouldShowFeedbackDialog(): boolean {
    return this.appConfigurationManager.getToggleValue(ToggleKeyEnum.autoTranslateButtonOn) &&
      this.title?.localeObject?.type?.value === TypeEnum.language &&
      this.isEditing();
  }

  /**
   * Starts the save process of the current form, if lockRequired is true locks the title.
   *
   * @param lockRequired boolean
   * @param modal any
   * @returns void
   */
  validateForm(lockRequired: boolean, modal: any): void {
    this.closeModal();
    this.lockRequired = lockRequired;
    this.isSaving = true;
    this.form.disable();

    const locale: Locale = new Locale({
      language: this.form.getLanguageField().value,
      territory: this.form.getTerritoryField().value,
      productType: this.form.getProductTypeField().value,
      account: this.form.getAccountField().value
    });

    this.subscriptions.add(
      this.productLayoutHelper.getLocaleFromLocaleIds(locale).subscribe(
        (localeString: string) => {
          this.currentLocale = localeString;

          this.titleService.fetchProductMetadata({
            productType: this.title.type,
            productId: this.title.id,
            locale: this.currentLocale
          }).subscribe(
            (localization: StormServiceResponseSingle) => {
              if (this.isEditing()) {
                this.save();
              } else {
                this.openModal(modal);
              }
            },
            (error: HttpError) => {
              if (error.is404()) {
                this.save();
              } else {
                this.notificationService.handleError('Failed trying to validate if there is a repeated locale', error);
                this.isSaving = false;
                this.form.enable();
              }
            }
          );
        },
        (error: HttpError) => {
          this.notificationService.handleError('Failed trying to save the localization, invalid locale', error);
          this.isSaving = false;
          this.form.enable();
        }
      )
    );
  }

  /**
   * Creates a form with the current title.
   *
   * @returns void
   */
  protected createForm(): void {
    const mappedData: any = _cloneDeep(this.title.originalData);

    switch (this.title.localeObject.type.value) {
      case TypeEnum.language:
        this.form = new LanguageLocalizationForm(this.languageFieldSpecs, this.attributes, this.title.originalData);
      break;
      case TypeEnum.territory:
        this.formHandlerService.groupFunctionalMetadataSplitFields(mappedData, LevelModeEnum.territory);

        const dateAttributes: string[] = [
          FeatureAttributes.firstDigitalRelease,
          FeatureAttributes.firstPhysicalRelease,
          FeatureAttributes.theatricalReleaseDate
        ];

        dateAttributes.forEach(
          (attribute: string) => {
            if (!_isUndefined(mappedData[attribute])) {
              mappedData[attribute] =
                (this.title[attribute] instanceof Date)
                ? this.title[attribute]
                : new Date(this.title[attribute]);
            }
          }
        );

        if (!_isUndefined(mappedData[FeatureAttributes.runtimeSeconds])) {
          mappedData[FeatureAttributes.runtimeSeconds] =
            (this.title[FeatureAttributes.runtimeSeconds] instanceof Time)
            ? this.title[FeatureAttributes.runtimeSeconds].time
            : new Time(this.title[FeatureAttributes.runtimeSeconds]).time;
        }

        this.form = new TerritoryLocalizationForm({ }, this.attributes, mappedData, this.hasFunctionalMetadataEnabled());

      break;
      case TypeEnum.account:
        this.formHandlerService.groupFunctionalMetadataSplitFields(mappedData, LevelModeEnum.account);
        this.form = new AccountLocalizationForm({ }, this.attributes, mappedData, this.hasFunctionalMetadataEnabled());
      break;
      default:
        throw new HttpError(`Invalid localization type given.`);
    }
  }

  /**
   * Destroys the current form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  /**
   * Prepares the current metadata.
   *
   * @returns any
   */
  protected prepareMetadata(): any {
    const metadata: any = this.form.toJson();

    if (this.title.isEpisode()) {
      metadata[EpisodeAttributes.seasonId] = (<EpisodeMetadata>this.title).seasonId;
    }

    if (this.title.isSeason()) {
      metadata[SeasonAttributes.seriesId] = (<SeasonMetadata>this.title).seriesId;
    }

    if (this.hasFunctionalMetadataEnabled() && (this.title.isEpisode() || this.title.isFeature())) {
      let functionalMetadataObj = { };
      const accountId: number[] = _isArray(this.form.getAccountField().value) ? this.form.getAccountField().value : [];
      const isDisneySvod: boolean = (accountId.length === 1) && Account.isDisneySvod(accountId[0]);

      if (this.title.localeObject.type.isAccount()) {
        functionalMetadataObj = this.functionalMetadataFormHandler.getFormValuesByLevel(this.form, LevelModeEnum.account);
      } else if (this.title.localeObject.type.isTerritory() && isDisneySvod) {
        functionalMetadataObj = this.functionalMetadataFormHandler.getFormValuesByLevel(this.form, LevelModeEnum.territoryAccount);
      }

      Object.assign(metadata, functionalMetadataObj);
    }

    metadata[TitleAttributes.locked] = this.lockRequired;

    const sanitizedMetadata = MetadataSanitizer.sanitizeEmptyLists(
      metadata,
      [
        FeatureAttributes.keywords,
        FeatureAttributes.genreId,
        FeatureAttributes.functionalMetadata,
        FeatureAttributes.primaryProductAssociation,
        FeatureAttributes.ratingId,
        FeatureAttributes.ratingSystemReasonId,
        FeatureAttributes.secondaryProductAssociation,
        FeatureAttributes.homeEntRatingId,
        FeatureAttributes.homeEntRatingSystemReasonId
      ]
    );

    return sanitizedMetadata;
  }
}
