import { Injectable } from '@angular/core';
import { Language } from '@bolt/ui-shared/master-data';

import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';


@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(
    protected listLayoutProvider: ListLayoutProvider
  ) { }
  /**
   * Returns the list of all possible locales with the given lists
   *
   * @param languages Language[]
   * @param territories number[]
   * @param productTypes number[]
   * @param accounts number[]
   * @returns string[]
   */
  getLocales(
    languages: Language[], territories: number[], productTypes: number[], accounts: number[]
  ): string[] {
    const locales: string[] = [];

    languages.forEach(
      (selectedLanguage: Language) => {
        territories.forEach(
          (territoryId: number) => {
            productTypes.forEach(
              (productTypeId: number) => {
                accounts.forEach(
                  (accountId: number) => {
                    const territoryCode = this.listLayoutProvider.getTerritoryById(territoryId).code;
                    const productTypeCode = this.listLayoutProvider.getProductTypeById(productTypeId).code;
                    const accountCode = this.listLayoutProvider.getAccountById(accountId).code;

                    locales.push(
                      selectedLanguage.code + '_' + territoryCode + '_' + productTypeCode + '_' + accountCode
                    );
                  }
                );
              }
            );
          }
        );
      }
    );

    return locales;
  }

  /**
   * Calculate the size of the possible locales for the given lists
   *
   * @param languages number[]| Language[]
   * @param territories number[]
   * @param productTypes number[]
   * @param accounts number[]
   * @returns number
   */
  getLocalesSize(
    languages: number[] | Language[],
     territories: number[],
     productTypes: number[],
     accounts: number[]
  ): number {
    const size = languages.length * territories.length * productTypes.length * accounts.length;

    return size;
  }
}
