import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule as BoltSharedAuthModule } from '@bolt/ui-shared/auth';
import { CommonModule as BoltSharedCommonModule } from '@bolt/ui-shared/common';
import { ConfigurationModule } from '@bolt/ui-shared/configuration';
import { DataShareModule } from '@bolt/ui-shared/data-share';
import { DataTransformModule } from '@bolt/ui-shared/data-transform';
import { DroplistsModule } from '@bolt/ui-shared/droplists';
import { FormModule as BoltSharedFormModule } from '@bolt/ui-shared/form';
import { MasterDataModule } from '@bolt/ui-shared/master-data';
import { MoratoriumModule } from '@bolt/ui-shared/moratorium';
import { NotificationModule } from '@bolt/ui-shared/notification';
import { RoutingModule } from '@bolt/ui-shared/routing';
import { SearchingModule } from '@bolt/ui-shared/searching';
import { TestingModule } from '@bolt/ui-shared/testing';
import { TitleModule } from '@bolt/ui-shared/title';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CustomFormsModule } from 'ng2-validation';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgbModal, NgbModule, NgbPaginationConfig, NgbTabset } from '@ng-bootstrap/ng-bootstrap';

import {
  AutoCompleteModule, CalendarModule, CheckboxModule, DialogModule, DragDropModule, MessageModule, RadioButtonModule, TooltipModule
} from 'primeng';

import { FileSelectDirective } from 'ng2-file-upload';
import { MomentModule } from 'ngx-moment';

import { AuthModule } from 'app/modules/auth/auth.module';
import { LayoutModule } from 'app/modules/layout/layout.module';
import { SharedModule } from 'app/shared/shared.module';

import { BoltCollectionCountersComponent } from './components/bolt-collection-counters/bolt-collection-counters.component';
import { BoltDropdownDataHeadersDirective } from './directives/bolt-dropdown-data-headers/bolt-dropdown-data-headers.directive';
import { BoltDropdownScrollLoadingDirective } from './directives/bolt-dropdown-scroll-loading/bolt-dropdown-scroll-loading.directive';
import { BoltLocaleFieldComponent } from './components/bolt-locale-field/bolt-locale-field.component';
import { BoltLockingStatusDetailsComponent } from './components/bolt-locking-status-details/bolt-locking-status-details.component';
import { BoltLockingStatusReasonComponent } from './components/bolt-locking-status-reason/bolt-locking-status-reason.component';
import { BoltMessageComponent } from './components/bolt-message/bolt-message.component';
import {
  BoltMetadataAttributeInfoMessageComponent
} from './components/bolt-metadata-attribute-info-message/bolt-metadata-attribute-info-message.component';
import { BoltPaginatedDropdownComponent } from './components/bolt-paginated-dropdown/bolt-paginated-dropdown.component';
import { BoltQuickAccessComponent } from './components/bolt-quick-access/bolt-quick-access.component';
import { BooleanToStringPipe } from './pipes/boolean-to-string/boolean-to-string.pipe';
import { GroupByPipe } from './pipes/group-by/group-by.pipe';
import { StormComponentStatusComponent } from './components/storm-component-status/storm-component-status.component';
import { StringEncoderService } from './services/string-encoder/string-encoder.service';
import { BoltDynamicTableComponent } from './components/bolt-dynamic-table/bolt-dynamic-table.component';
import { EditHistoryService } from './services/edit-history.service';
import { BoltEditHistoryComponent } from './components/bolt-edit-history/bolt-edit-history.component';
import { BoltEditHistoryHeaderComponent } from './components/bolt-edit-history-header/bolt-edit-history-header.component';
import { BoltEditHistoryMapperComponent } from './components/bolt-edit-history-mapper/bolt-edit-history-mapper.component';
import { BoltAutopopulateMetadataComponent } from './components/bolt-autopopulate-metadata/bolt-autopopulate-metadata.component';
import { BoltAutopopulateMetadataListComponent } from './components/bolt-autopopulate-metadata-list/bolt-autopopulate-metadata-list.component';
import { BoltAutopopulateItemComponent } from './components/bolt-autopopulate-metadata-item/bolt-autopopulate-metadata-item.component';
import { BoltAutoTranslateMetadataComponent } from './components/bolt-autotranslate-metadata/bolt-autotranslate-metadata.component';
import { BoltAutoTranslateMetadataFieldComponent } from './components/bolt-autotranslate-metadata-field/bolt-autotranslate-metadata-field.component';
import { BoltAutoTranslateFeedbackDialogComponent } from './components/bolt-autotranslate-feedback-dialog/bolt-autotranslate-feedback-dialog.component';
import { BoltAutoTranslateFeedbackQuestionComponent } from './components/bolt-autotranslate-feedback-question/bolt-autotranslate-feedback-question.component';


@NgModule({
    imports: [
      // Angular
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      // @bolt/ui-shared
      BoltSharedCommonModule,
      BoltSharedAuthModule,
      BoltSharedFormModule,
      ConfigurationModule,
      DataShareModule,
      DataTransformModule,
      DroplistsModule,
      MasterDataModule,
      MoratoriumModule,
      NotificationModule,
      RoutingModule,
      SearchingModule,
      TestingModule,
      TitleModule,
      // Bolt UI & Others
      SharedModule,
      AuthModule,
      LayoutModule,
      NgbModule,
      TabsModule,
      PaginationModule,
      ModalModule.forRoot(),
      TooltipModule,
      MessageModule,
      AutoCompleteModule,
      CalendarModule,
      DragDropModule,
      CustomFormsModule,
      CodemirrorModule,
      MomentModule,
      CheckboxModule,
      RadioButtonModule,
      DialogModule
    ],
    declarations: [
      BoltAutopopulateMetadataComponent,
      BoltAutopopulateMetadataListComponent,
      BoltAutoTranslateMetadataComponent,
      BoltAutoTranslateMetadataFieldComponent,
      BoltAutoTranslateFeedbackDialogComponent,
      BoltAutoTranslateFeedbackQuestionComponent,
      BoltAutopopulateItemComponent,
      BoltLockingStatusDetailsComponent,
      BoltLockingStatusReasonComponent,
      FileSelectDirective,
      StormComponentStatusComponent,
      BoltLocaleFieldComponent,
      GroupByPipe,
      BooleanToStringPipe,
      BoltCollectionCountersComponent,
      BoltDropdownDataHeadersDirective,
      BoltDropdownScrollLoadingDirective,
      BoltEditHistoryComponent,
      BoltEditHistoryHeaderComponent,
      BoltEditHistoryMapperComponent,
      BoltMessageComponent,
      BoltMetadataAttributeInfoMessageComponent,
      BoltPaginatedDropdownComponent,
      BoltQuickAccessComponent,
      BoltDynamicTableComponent,
    ],
    exports: [
      // Angular
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      // @bolt/ui-shared
      BoltSharedCommonModule,
      BoltSharedAuthModule,
      BoltSharedFormModule,
      ConfigurationModule,
      DataShareModule,
      DataTransformModule,
      DroplistsModule,
      MasterDataModule,
      MoratoriumModule,
      NotificationModule,
      RoutingModule,
      SearchingModule,
      TestingModule,
      TitleModule,
      // Bolt UI & Others
      BoltAutopopulateMetadataComponent,
      BoltAutoTranslateMetadataComponent,
      BoltAutoTranslateFeedbackDialogComponent,
      StormComponentStatusComponent,
      BoltLocaleFieldComponent,
      BoltLockingStatusDetailsComponent,
      BoltLockingStatusReasonComponent,
      SharedModule,
      AuthModule,
      LayoutModule,
      NgbModule,
      TabsModule,
      PaginationModule,
      ModalModule,
      TooltipModule,
      FileSelectDirective,
      MessageModule,
      AutoCompleteModule,
      CalendarModule,
      DragDropModule,
      CustomFormsModule,
      CodemirrorModule,
      MomentModule,
      CheckboxModule,
      RadioButtonModule,
      DialogModule,
      GroupByPipe,
      BooleanToStringPipe,
      BoltCollectionCountersComponent,
      BoltEditHistoryComponent,
      BoltMessageComponent,
      BoltMetadataAttributeInfoMessageComponent,
      BoltPaginatedDropdownComponent,
      BoltQuickAccessComponent,
      BoltDynamicTableComponent,
    ],
    providers: [
      NgbPaginationConfig,
      NgbModal,
      NgbTabset,
      StringEncoderService,
      EditHistoryService
    ]
})
export class BoltCommonModule { }
